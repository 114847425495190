import ContentWrapper from 'Component/ContentWrapper';
import ExpandableContent from 'Component/ExpandableContent';
import {
    FooterComponent as SourceFooterComponent,
} from 'SourceComponent/Footer/Footer.component';

import './Footer.override.style';

/** @namespace Radianonline/Component/Footer/Component */
export class FooterComponent extends SourceFooterComponent {
    renderColumn(column, i) {
        const {
            title,
            columnActiveKey,
            items,
        } = column;

        if (columnActiveKey && !(columnActiveKey in this.props)) {
            return null;
        }

        return (
            <ExpandableContent
              heading={ title }
              mix={ { block: 'Footer', elem: 'ColumnContent' } }
              key={ i }
              isArrow
            >
                { items.map(this.renderColumnItem.bind(this)) }
            </ExpandableContent>
        );
    }

    renderCopyrightContent() {
        const { copyright } = this.props;

        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'CopyrightContent' } }
              label=""
            >
                <span block="Footer" elem="Copyright">
                    { copyright }
                </span>
            </ContentWrapper>
        );
    }
}

export default FooterComponent;
